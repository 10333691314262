import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"
import animateScrollTo from 'animated-scroll-to';

export default class Slider {

  constructor(selector) {
    this.sliders = document.querySelectorAll(selector);
    if (!this.sliders) {
      return;
    }

    for (let i = 0; i < this.sliders.length; ++i) {
      const slider = this.sliders[i];

      if (slider.dataset.sliderInitialized) {
        continue;
      }

      slider.dataset.sliderInitialized = true
      const sliderItems = slider.querySelectorAll('.slider--item');

      if (sliderItems.length > 1) {
        const options = {
          container: slider,
          mode: 'gallery',
          speed: slider.dataset.speed ? parseInt(slider.dataset.speed) : 2500,
          autoplay: true,
          autoplayTimeout: slider.dataset.timeout ? parseInt(slider.dataset.timeout) : 5000,
          autoplayButtonOutput: false,
          controls: false,
          nav: true,
        }
        const sliderInstance = tns(options);
      }
    }

    const heroImages = document.querySelector('.hero--images');
    const heroScrollTrigger = document.querySelector('.hero--scroll-trigger');
    if (heroScrollTrigger) {
      heroScrollTrigger.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();
        const heroRect = heroImages.getBoundingClientRect();
        const _options = {
          speed: 500,
          minDuration: 250,
          maxDuration: 750,
        };

        animateScrollTo([0, heroRect.bottom - 20], _options)
      });
    }

  }

}
